import React from "react";
import Quote from "../components/Quote";

function Home() {
  return (
    <div className="text-center">

      <div>
        <p className="p-4 mb-3 text-justify bg-white/75">
          Welcome to the world of Von Del Chamberlain's captivating novel, Children of the Sky. 
          Here, you'll uncover a unique blend of literature, culture, and astronomy. 
          Explore this site for more information on astronomy and the ancient Skidi Pawnee!
        </p>
      </div>

      <div className="md:flex">

        <div>
          <img className="w-3/5 md:w-3/5  m-auto mb-2" src="images/cover.jpg" alt="Book cover for Children of the Sky by Von Del Chamberlain." />
          <a href="https://a.co/d/02LIHZxe" target="_blank" rel="noreferrer">
            <button type="button" class=" mb-2 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              Order on Amazon!
            </button>
          </a>
          <p className="hidden md:block">
            Available in Pawnee Country!<br/>
            <span className="font-semibold">WHERE THE BUFFALO ROAM</span><br/>
            Gift Shop<br/>
            648 Harrison Street<br/>
            Pawnee OK 74058  
          </p>
        </div>

        <div className="flex-grow">
          <div className="text-center grid grid-cols-1 gap-4 p-4 pt-0 mb-4">
            <Quote 
              text = '"A highly regarded astronomy educator and cultural astronomy researcher applies knowledge of rich Skidi Pawnee cosmic traditions to imagine what life might have been like for people on the Great American Plains during the 1600s as they struggled with the land and with each other under the guidance of the Sun, Moon planets, and stars. Now he shares this insightful vision with us in Children of the Sky."'
              name = "Victor B. Fisher,"
              title = "Associate Professor of Anthropology, Towson University"
            />
            <Quote 
              text = '"Children of the Sky is a fascinating fictional account of how the Sun, Moon, planets, and stars played an important part in Skidi Pawnee ceremonial ways 400 years ago."'
              name = "Pat Leading Fox"
              title = "Head Chief of the Skidi Pawnee"
            />
            <Quote 
              text = '"Von Del Chamberlain, an astronomer, and educator has written a wonderful new novel, Children of the Sky. His love of the land, the Pawnee people, and their history is reflected in this highly recommended gripping saga of a people and the stars that deeply influence their culture."'
              name = "Richard Gould"
              title = "Administrator, Pawnee Indian Village Museum"
            />
            <Quote 
              text = '"Author, astronomer, Von Del Chamberlain, is a master in combining the very different disciplines of astronomy, geography and ethnography. He has taken this insight and extensive research to a new level by expanding it into an epic novel, Children of the Sky. The book not only describes the lifeways of an intelligent people with star-based religion that lived 400 years ago but also gives this information a human face."'
              name = "Bob Blasing"
              title = "Retired Area Archeologist, Bureau of Reclamation, Oklahoma-Texas Area Office"
            />
          </div>
        </div>

      </div>

      <div>
        <p className="md:hidden">
            Also Available in Pawnee Country!<br/>
            <span className="font-semibold">WHERE THE BUFFALO ROAM</span><br/>
            Gift Shop<br/>
            648 Harrison Street<br/>
            Pawnee OK 74058  
          </p>
      </div>

    </div>
  );
}

export default Home;
