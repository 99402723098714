import React from 'react'

const Link = ({text, url}) => (
  <div>
    <p>
      {text}<br/>
      <a className="underline" href={"https://" + url} target="_blank" rel="noreferrer">{url}</a>
    </p>
  </div>
)

export default Link