import React from 'react'

const Quote = ({text, name, title}) => (
  <blockquote className="text-justify m-auto bg-white/50 p-2 shadow relative">
    <div className="relative left-0 top-0 text-xl text-[#2A54A0]"><i class="fa-solid fa-quote-left"></i></div>
    <p>{text}</p>
    <footer>
      <cite className="not-italic font-semibold">{name}</cite>
      <br />
      <cite>{title}</cite>
    </footer>
  </blockquote>
)

export default Quote