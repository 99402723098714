import React from "react";

function TheBook() {
  return (
    <div className="m-5 bg-white/75 p-4 space-y-4 text-lg md:text-md">
      <p><span className="brushtip-travis">Children of the Sky</span> is a fictional story based on what is known to have governed the lives of the ancient Skidi Band of Pawnee Indians.  Their traditions, emerging from remote times, were nurtured and maintained, yet ever evolved due to circumstances and natural phenomena.  The story spans the life of a religious leader from birth (1602) through violent death (1680).</p> 
      <p>Born during the night of a dramatic meteor blizzard, the man who became known by his people as Flying Stars, along with other leaders, guides the Skidi people through droughts, conflicts with others as well as strife from within, and awareness of ever-growing pressures from white invaders onto the American landscape. This is a story of exploration and discovery through learning from others, believing that "everything is connected," and increasing knowledge by "looking closely at everything."</p>
      <div className="md:grid grid-cols-2 gap-4">
        <div className="place-content-center">
          <img className="w-4/5 md:w-2/3 m-auto mb-4 md:mb-0" src="images/eclipseandanimals.png" alt="Eclipse surrounded by animals. Clockwise: black bear, fox, bobcat, and mountain lion."/>
          <p className="text-center">Eclipse photo​ by <strong>Bruce Feagle</strong>, 2017</p>
        </div>
        <div className="w-1/3 text-center w-fit">          
          <img className="md:w-1/2 m-auto" src="images/cover.jpg" alt="Book cover for Children of the Sky by Von Del Chamberlain."/>
          <p className ="m-auto italic">Cover features <strong>A.J. LeadingFox</strong><br/>Skidi Pawnee Straight Dancer</p>
        </div>
      </div>
      <p>It is, as well, the story of the land itself, stretching from the magnificent Plains to the eastern front of the towering Rocky Mountains, where the Skidi continually gained a greater understanding of themselves amid physical realities of seasons, landscapes, weather, water, rocks, and plants. Living along rambling rivers and sparkling streams flowing through grasslands dancing to rhythms of singing winds adorned here and there with forested prairie lands, hunting herds of bellowing buffalo and other game, finding tasty wild fruits, berries, and roots, while planting and harvesting crops of corn, beans, and squash, these people carefully watched the Sun, Moon, planets, and stars.</p>
      <p>The continual astronomical displays, simple and complex, observable upon the great vault of heaven above, were interpreted by intelligent minds of people surrounded by the exquisite beauty of Mother Earth watched over by the grandeur of star-studded Father Sky.</p>
      <p>Children of the Sky uniquely exemplifies the age-old struggle of people everywhere to survive, discover and pass on knowledge. That theme is enhanced in Von Del Chamberlain's story with descriptions of law, social order, religion, and dramatic ceremonial traditions.  The importance of unity within families and cultures grows as the story unfolds to the concluding hope for eventual unification throughout the region and ultimately beyond. Thus, we discover that principles so highly valued in America today resided here long before the arrival of European stock.</p>
    </div>
  );
}

export default TheBook;
