import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import TheBook from "./pages/TheBook";
import ForReaders from "./pages/ForReaders";
import TheAuthor from "./pages/TheAuthor";
import PhotosPress from "./pages/PhotosPress";
import Contact from "./pages/Contact";
import Links from "./pages/Links";

import Header from "./components/Header";

import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <div className="bg-[url('bg.jpg')] bg-cover md:h-dvh lg:h-max pb-2">
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />        
        <Route path="/forthereaders" element={<ForReaders />} />
        <Route path="/thebook" element={<TheBook />} />
        <Route path="/theauthor" element={<TheAuthor />} />
        <Route path="/press" element={<PhotosPress />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/links" element={<Links />} />
      </Routes>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
