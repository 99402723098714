import React from "react";

function TheAuthor() {
  return (
    <div className="text-lg md:text-md text-justify">
      <div className="m-5 bg-white/75 p-4 lg:grid grid-cols-5">      
        <div className="p-4 h-full grid content-center">
          <img className="m-auto" src="images/vondelandsky.jpg" alt="Von Del Chamberlain standing in front of a blue sky."/>
        </div>
        <div className="col-span-4 space-y-4">
          <p><strong>Von Del Chamberlain</strong> has focused his career on interpretation and education about what we can observe in a country-dark starlit sky. Using resources and technologies found in museums and planetariums, he has directed the production and presentation of both simple and elaborate planetarium shows with titles such as Once Upon a Starry Night, Islands in the Sky, Footprints on the Moon, Stardust, Star Pioneers, Galaxies and Journey Through the Universe. His work has carried him from the Robert T. Longway Planetarium in Flint, Michigan, to the Abrams Planetarium at Michigan State University, the Smithsonian National Air and Space Museum, and the Hansen Planetarium in Salt Lake City. In addition, he has instructed courses for the University of Michigan, Michigan State University, the University of Utah, and Utah Valley State College (now Utah Valley University).</p>
          <p>Mr. Chamberlain has engaged audiences under starry skies in natural settings ranging from National Parks, ships cruising open seas, float trips on various rivers, including through the Grand Canyon, and around campfires in sundry places, including his own backyard.</p>
          <p>His writings include published science and education papers, books, and newspaper articles. His best-known book, When Stars Came Down to Earth: Cosmology of the Skidi Pawnee Indians of North America, is the foundation for this novel.</p>
          <p>Having explored a bit of Mother Earth, now living where he was born in the red rock community of Kanab, Utah, Von Del said of himself, "The better he knows the cosmos, the more he loves the Earth."</p>
        </div>
      </div>

      <div className="m-5 bg-white/75 p-4">
        <div><p className="italic">A note from the author:</p></div>
        <div className="text-xl text-[#2A54A0]"><i class="fa-solid fa-quote-left"></i></div>
        <div className="space-y-4">
          <p>I am so glad and honored that you are reading my book.</p>
          <p>We live at a most amazing time of discovery. Of things in our immediate vicinity and distant surroundings, things long ago and far away, some happening fast, some slowly, some rarely, and others every day. Never before have we been learning at such a rapid pace.</p>
          <p>We have been able to discern the existence of planets orbiting thousands of stars and are attempting to learn what some of these planets are like. Since there are so many other planets out there, and since we are here on planet Earth, we might soon know that we are not the only thinking creatures in the cosmos.</p>
          <p>The new James Webb telescope is revealing considerable new information about some of the planets and even more about how stars and planetary systems continue to form all around us. It is an amazing universe out there, and we can learn about it better than ever before. If you have not done so, please get in the habit of looking every day at the <a className="underline" href="https://apod.nasa.gov/apod/astropix.html" target="_blank" rel="noreferrer">Astronomy Picture of the Day</a>. Among the daily images posted, many reveal to your own eyes objects that have taken the entire history of the universe, about 14 billion years, to be available to see. I look at this site every night before sleeping. They post new images soon after midnight Eastern Time.</p>
          <p>Some of the most important things relating to Children of the Sky that I hope you will enjoy are easily available and most inspiring of all. I refer to the most beautiful sights that human eyes have ever beheld, and they are free to all—the dark, starry sky on a clear night. It is this that I most hope all my readers are enjoying. Over the entire history of humanity, the sky has influenced us more than anything else I can think of. The study of the sky helps us know how to live successfully and keep track of time, not just from moment to moment and day to day, but from season to season, year to year, and century to century.  This book is a testament to how such observation of the objects of the sky influences the beliefs and actions of various groups of people.</p>
          <p>More than anything else, I hope readers of Children of the Sky will watch the things that determine the story revealed here. The changing elements of the sky reveal and govern the story. Right now is the perfect time to study this in your currently visible sky. One of the book's primary characters is Oopihdee-koochoo, the planet Mars. As I write this in early December 2022, we on planet Earth are passing right between the Sun and Mars, meaning that Mars is close to us and that makes Mars appear brightest in our sky. It rises in the east shortly after sunset and can easily be seen passing across the sky throughout the night, setting in the west in the early morning. It will remain bright for many weeks, then slowly dim in the coming months. Meanwhile, the other primary sky character of our story, Choopihdit-tahkah, the planet Venus, is just becoming visible in the western evening sky, day by day getting brighter and higher up to be more easily seen.   Over months of time, Mars will steadily move westward in the evening sky while Venus moves higher up. You can watch these two play out the parts that you read about in Children of the Sky. It is the changing aspects of these two planets that create the story of this book, and you can study this with your own eyes as you follow the chapters and activities of the human characters who base much of their beliefs and actions on what they see up there. I hope you will enjoy doing this in order to appreciate the story more deeply.</p>
          <p>Presently, the American space program is actively working to establish human colonies on both the Moon and Mars. The Skidi Pawnee people considered Sky Father Oopihdee-koochoo(Mars) and the Sky Mother Pah (Moon) to be their own sky ancestors. How interesting it is to witness changing cultural understandings of the things surrounding us, things that continue to inspire us as we explore the cosmos.</p>
          <p>Here is an article you might enjoy: <a className="underline" href="https://sciencepublishinggroup.com/article/10.11648/j.ija.20221002.11" target="_blank">Conscience of the Cosmos: Thinking About Mother Earth and Father Sky. International Journal of Archaeology.</a></p>
        </div>
      </div>

      <div className="m-5 bg-white/75 p-4 md:flex md:space-x-4">        
        <iframe className="w-full" width="560" height="315" src="https://www.youtube.com/embed/8bvC_o11MFw?si=l9XMZyvwXM9Rvr5J" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <p>In 2017, the Kanab (Utah) City Library conducted interviews with those who grew up during the nuclear testing era. Mr. Chamberlain shared his memories, his predictions regarding future tests, and his hope for peaceful uses of nuclear energy.</p>
      </div>

      <div className="m-5 bg-white/75 p-4 space-y-4">
        <p>Other books by Von Del Chamberlain</p>
        <div className="md:flex md:space-x-4 space-y-4 md:space-y-0">
          <div><a href="https://a.co/d/0dV4zvQW" target="_blank" rel="noreferrer"><img className="w-full md:w-auto md:h-80" src="images/whenstarscomedowntoearthbook.jpg" alt="Book cover for When Stars Come Down to Earth by Von Del Chamberlain."/></a></div>
          <div><a href="https://a.co/d/09EEXK31" target="_blank" rel="noreferrer"><img className="w-full md:w-auto md:h-80" src="images/songsfromtheskybook.jpg" alt="Book cover for Songs from the Sky by Von Del Chamberlain."/></a></div>
        </div>
      </div>

  </div>
  );
}

export default TheAuthor;
