import React from "react";
import Link from "../components/Link";

function Links() {
  return (
    <div className="text-lg md:text-md text-justify m-5 bg-white/75 p-4 space-y-4">
      <p>Links for more information relating to topics in <span className="brushtip-travis">Children of the Sky</span>:</p>
      <Link
        text="Pawnee Earth Lodge at the Field Museum of Natural History in Chicago"
        url="www.fieldmuseum.org/exhibitions/pawnee-earth-lodge"
      />
      <Link
        text="Pawnee History"
        url="pawneenation.org/pawnee-history"
      />
      <Link
        text="Pawnee Nation News"
        url="pawneenation.org/chaticks-si-chaticks"
      />
      <Link
        text="Pawnee Indian Museum State Historic Site"
        url="www.travelks.com/listing/pawnee-indian-museum-state-historic-site/1455/"
      />
      <Link
        text="The easy way to follow the Sun, Moon, planets, and stars for the rest of your life:"
        url="abramsplanetarium.org/skycalendar"
      />
      <Link
        text="Feast your eyes on the most spectacular vistas of the cosmos (Astronomy Picture of the Day):"
        url="apod.nasa.gov/apod/astropix.html"
      />
      <Link
        text="Publications for everyone wanting to keep up with what is going on in astronomy:"
        url="skyandtelescope.com"
      />
      <Link
        text="Astronomy.com"
        url="astronomy.com"
      />
      <p>
        Visit places your Travel Agent knows nothing about at your <strong>local planetarium</strong>!
        If you want to look through telescopes at the Moon, planets, stars, and galaxies, contact your local astronomy club.
      </p>
    </div>
  );
}

export default Links;
