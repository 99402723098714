import React from "react";

function ForReaders() {
  return (
    <div className="text-lg md:text-md text-center md:h-dvh">
      <div className="m-5 bg-white/75 p-4 text-justify space-y-4">
        <div><p className="italic">A note from the author:</p></div>
        <div className="text-xl text-[#2A54A0]"><i class="fa-solid fa-quote-left"></i></div>
        <p>"From the first to last cultures of planet Earth, all around in every place and every time, people have watched both earth and sky. They paid particular attention to those few celestial objects (the planets) that migrated among the many others (the stars) and especially to those that were the biggest and brightest. These are the leading characters of the book you are reading – namely Shukooloo (Sun), Pah (Moon), Oopihdee-koochoo (Mars), and Choopihdit-tahkah (Venus).</p>
        <p>Never in all of human history has there been a more relevant time for individuals to personally study these objects that surround us. Each of us has spent our lives in the presence of the Sun, basked in Moon light. We have noticed the brilliance and beauty of Venus and the fiery glow of Mars blazing through the heavens. And, right now, humans have in mind-sight the exploration, perhaps even colonization, of planet Mars. Indeed, this is a very good time for individual observational study of these most significant objects, especially for readers of Children of the Sky.</p>
        <p>So, please, go out early in the morning before the glow of twilight washes out the darkness from the sky, and <strong>find the dim planet Mars.</strong> Begin your study of the migration of Oopihdee-koochoo from east to west in quest of his mate. You might even see them join together among the cluster of stars known by the Skidi band of Pawnee Indians as “the Family” (Pleiades).  </p>
        <p>Please complete your experience of the book by engaging in personal study to understand what inspired the story of those Children of the Sky."</p>
        <br/><a className="underline" href="https://theskylive.com/where-is-mars" target="_blank" rel="noreferrer">https://theskylive.com/where-is-mars</a>
      </div>
    </div>
  );
}

export default ForReaders;
