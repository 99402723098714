import React from "react";

function PhotosPress() {
  return (
    <div className="text-lg md:text-md text-center h-dvh">
      <div className="m-5 bg-white/75 p-4 text-justify space-y-4">
        <p>We are happy to help you write or report your story about Children of the Sky and Von Del Chamberlain. Please download our recent press release and contact us through our contact page.</p>
        <p>Thank you for your interest!</p>
      </div>
      <a className="m-auto" href="https://storage.googleapis.com/data-downloads-by-bryce/Press%20Release%20Children%20of%20the%20Sky.pdf" target="_blank" rel="noreferrer">
        <button type="button" class=" mb-2 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          Download Press Release
        </button>
      </a>
    </div>
  );
}

export default PhotosPress;
