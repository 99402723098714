import React from "react";

function Contact() {
  return (
    <div className="bg-white/75 h-fill grid grid-cols-1 md:pt-4">
        <div className="md:hidden">
          <iframe 
            src="https://docs.google.com/forms/d/e/1FAIpQLScRn58gKU-q9wBQ3_pc4P2GO7K5UtDQDYOPQ-UwhRwqFxl_5Q/viewform?embedded=true" 
            width="400" height="950" frameborder="0" marginheight="0" marginwidth="0"
          >
            Loading…
          </iframe>
        </div>
        <div className="hidden md:block m-auto">
          <iframe 
            src="https://docs.google.com/forms/d/e/1FAIpQLScRn58gKU-q9wBQ3_pc4P2GO7K5UtDQDYOPQ-UwhRwqFxl_5Q/viewform?embedded=true" 
            width="600" height="950" frameborder="0" marginheight="0" marginwidth="0"
          >
            Loading…
          </iframe>
        </div>
    </div>
  );
}

export default Contact;
