// https://tailwindui.com/components/marketing/sections/heroes

import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

const navigation = [
  { name: 'Home', href: '/' },
  { name: 'For the Readers', href: '/forthereaders' },
  { name: 'The Book', href: '/thebook' },  
  { name: 'The Author', href: '/theauthor' },   
  { name: 'Press', href: '/press' },  
  { name: 'Contact', href: '/contact' },  
  { name: 'Discover More', href: '/links' },  
]

function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className="brushtip-travis text-2xl">
      <header className="bg-black/35">

        <nav className="flex items-center justify-between p-6 pb-3 lg:px-8" aria-label="Global">
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a key={item.name} href={item.href} className="text-md leading-6 text-white">
                {item.name}
              </a>
            ))}
          </div>
          <div>
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          </div>
        </nav>

        <Dialog className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 left-0 z-50 w-full overflow-y-auto bg-[url('bg.jpg')] bg-cover sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="bg-white/60 h-full px-6 py-6 ">
            
              <div className="flex items-center justify-between">
                <a href="#" className="-m-1.5 p-1.5"/>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-4 py-6">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="-mx-3 block px-3 py-2 pb-1 pt-4 text-gray-900 brushtip-travis text-2xl bg-white/50"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>

            </div>
          </Dialog.Panel>
        </Dialog>
      </header>      
    </div>
  )
}

export default Header;